import BaseLayout from '../layouts/BaseLayout';

function AGBPage() {
  const content = (
    <>
      <h3 className='h3'>AGB</h3>
      <p>
        <strong>Inhaltverzeichnis</strong>
      </p>
      <p>
        <a href='#scope'>1. Geltungsbereich</a>
        <br />
        <a href='#partners'>2. Vertragspartner</a>
        <br />
        <a href='#contract'>3. Vertragsschluss</a>
        <br />
        <a href='#revocation'>4. Widerrufsrecht</a>
        <br />
        <a href='#prices'>5. Preise und Versandkosten</a>
        <br />
        <a href='#delivery'>6. Lieferung</a>
        <br />
        <a href='#payment'>7. Zahlung</a>
        <br />
        <a href='#reservation'>8. Eigentumsvorbehalt</a>
        <br />
        <a href='#warranty'>9. Sachmängelgewährleistung</a>
        <br />
        <a href='#dispute'>10. Streitbeilegung</a>
      </p>
      <div id='scope'>
        <h4>1. Geltungsbereich</h4>
        <p>
          Für alle Lieferungen von miapapeteria an Verbraucher gelten diese
          Allgemeinen Geschäftsbedingungen (AGB). Verbraucher ist jede
          natürliche Person, die ein Rechtsgeschäft zu einem Zwecke abschließt,
          der überwiegend weder ihrer gewerblichen noch ihrer selbstständigen
          beruflichen Tätigkeit zugerechnet werden kann.
        </p>
      </div>
      <div id='partners'>
        <h4>2. Vertragspartner</h4>
        <p>
          Der Kaufvertrag kommt zustande mit miapapeteria, Inhaberin: Mia
          Hoffmeister, Heuwaagstraße 7, 91054 Erlangen.
        </p>
      </div>
      <div id='contract'>
        <h4>3. Vertragsschluss</h4>
        <p>
          <strong>3.1.</strong> Die Darstellung der Produkte im Online-Shop
          stellt kein rechtlich bindendes Angebot, sondern nur eine Aufforderung
          zur Bestellung dar.
        </p>
        <p>
          <strong>3.2.</strong> 3.2. Durch Anklicken des Buttons [jetzt
          Bezahlen] oder [später Bezahlen mit Rechnung] geben Sie eine
          verbindliche Bestellung der auf der Bestellseite aufgelisteten Waren
          ab. Ihr Kauf-vertrag kommt zustande, wenn wir Ihre Bestellung durch
          eine Auftragsbestätigung per E-Mail unmittelbar nach dem Erhalt Ihrer
          Bestellung annehmen.
        </p>
      </div>
      <div id='revocation'>
        <h4>4. Widerrufsrecht</h4>
        <p>
          <strong>4.1.</strong> Wenn Sie Verbraucher sind (also eine natürliche
          Person, die die Bestellung zu einem Zweck abgibt, der weder Ihrer
          gewerblichen oder selbständigen beruflichen Tätigkeit zugerechnet
          werden kann), steht Ihnen nach Maßgabe der gesetzlichen Bestimmungen
          ein Widerrufsrecht zu.
        </p>
        <p>
          <strong>4.2.</strong> Machen Sie als Verbraucher von Ihrem
          Widerrufsrecht nach Ziffer 4.1 Gebrauch, so haben Sie die regelmäßigen
          Kosten der Rücksendung zu tragen.
        </p>
        <p>
          <strong>4.3.</strong> Im Übrigen gelten für das Widerrufsrecht die
          Regelungen, die im Einzelnen wiedergegeben sind in der folgenden
        </p>{' '}
        <strong>Widerrufsbelehrung</strong> <br></br>
        <br></br> <strong> Widerrufsrecht</strong> <br></br>
        <br></br> Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von
        Gründen diesen Vertrag zu widerrufen. Die Widerrufsfrist beträgt
        vierzehn Tage ab dem Tag an dem Sie oder ein von Ihnen benannter
        Dritter, der nicht der Beförderer ist, die Waren in Besitz genommen
        haben bzw. hat. <br></br>
        <br></br> Um Ihr Widerrufsrecht auszuüben, müssen Sie uns [miapapeteria,
        Heuwaagstr. 7, 91054 Erlangen, +49157037904180, info@miapapeteria.de]
        mittels einer eindeutigen Erklärung (zB ein mit der Post versandter
        Brief oder eine E-Mail) über Ihren Entschluss, diesen Vertrag zu
        widerrufen, informieren. Sie können dafür das beigefügte
        Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist.
        Sie können das Muster-Widerrufsformular oder eine andere eindeutige
        Erklärung auch auf unserer Webseite (Internet-Adresse einfügen)
        elektronisch ausfüllen und übermitteln. Machen Sie von dieser
        Möglichkeit Gebrauch, so werden wir Ihnen unverzüglich (zB per E-Mail)
        eine Bestätigung über den Eingang eines solchen Widerrufs übermitteln.
        <br></br>
        <br></br> Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die
        Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
        Widerrufsfrist absenden.<br></br>
        <br></br> <strong>Folgen des Widerrufs</strong>
        <br></br>
        <br></br> Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle
        Zahlungen, die wir von Ihnen erhalten haben, einschließlich der
        Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus
        ergeben, dass Sie eine andere Art der Lieferung als die von uns
        angebotene, günstigste Standardlieferung gewählt haben), unverzüglich
        und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem
        die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen
        ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das
        Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn,
        mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall
        werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die
        Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben
        oder bis Sie den Nachweis erbracht haben, dass Sie die Waren
        zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist.
        <br></br>
        <br></br> Sie haben die Waren unverzüglich und in jedem Fall spätestens
        binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf
        dieses Vertrages unterrichten, an uns oder an miapapeteria, Heuwaagstr.
        7, 91054 Erlangen zurückzusenden oder zu übergeben. Die Frist ist
        gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen
        absenden. Sie tragen die unmittelbaren Kosten der Rücksendung der Waren.
        Sie müssen für einen etwaigen Wertverlust der Waren nur aufkommen, wenn
        dieser Wertverlust auf einen zur Prüfung der Beschaffenheit,
        Eigenschaften und Funktionsweise der Waren nicht notwendigen Umgang mit
        ihnen zurückzuführen ist.<br></br>
        <br></br>
        <strong>- Ende der Widerrufsbelehrung –</strong>
        <p>
          <strong>4.4.</strong> Über das Muster-Widerrufsformular informiert
          DemoShop nach der gesetzlichen Regelung wie folgt:
          <div className='withdrawal-form'>
            <strong>Muster-Widerrufsformular</strong>
            <p>
              (Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte
              dieses Formular aus und senden Sie es zurück.)
            </p>
            <ul>
              <li>
                An [miapapeteria, Heuwaagstr. 7, 91054 Erlangen,
                info@miapapeteria.de]
              </li>
              <li>
                Hiermit widerrufe(n) ich/wir (*) den von mir/uns (*)
                abgeschlossenen Vertrag über den Kauf der folgenden Waren
                (*)/die Erbringung der folgenden Dienstleistung (*)
              </li>
              <li>Bestellt am (*)/erhalten am (*)</li>
              <li>Name des/der Verbraucher(s)</li>
              <li>Anschrift des/der Verbraucher(s)</li>
              <li>
                Unterschrift des/der Verbraucher(s) (nur bei Mitteilung auf
                Papier)
              </li>
              <li>Datum __________</li>
              <li>(*) Unzutreffendes streichen</li>
            </ul>
          </div>
        </p>
      </div>
      <div id='prices'>
        <h4>5. Preise und Versandkosten</h4>
        <p>
          <strong>5.1.</strong> Die auf den Produktseiten genannten Preise sind{' '}
          <b>Endpreise</b> zuzüglich Versandkosten. Im Sinne von § 19 UStG wird
          <b>keine Umsatzsteuer erhoben</b> und demnach auch nicht ausgewiesen.
        </p>
        <p>
          <strong>5.2.</strong> Zusätzlich zu den angegebenen Preisen berechne
          ich für die Lieferung innerhalb Deutschlands pauschal 4,99 Euro pro
          Bestellung. Die Versandkosten werden Ihnen auf den Produktseiten, im
          Warenkorbsystem und auf der Bestellseite nochmals deutlich mitgeteilt.
        </p>
        <p>
          <strong>5.3.</strong> Ab einem Warenwert von 50 Euro wird die
          Bestellung versandkostenfrei geliefert.
        </p>
      </div>
      <div id='delivery'>
        <h4>6. Lieferung</h4>
        <p>
          <strong>6.1.</strong> Die Lieferung erfolgt nur innerhalb Deutschlands
          mit DHL oder wenn möglich persönlich mit dem Rad.
        </p>
        <p>
          <strong>6.2.</strong> Bestellte Ware verlässt innerhalb 2-10 Werktagen
          (Mo. - Fr.) mein Lager. Die zusätzliche Lieferzeit des
          Versand-Dienstleisters beträgt bis zu 3-5 Werktage.
        </p>
        <p>
          <strong>6.3</strong> Versandkosten in Höhe von 4,90 Euro für
          Warenlieferungen innerhalb Deutschlands werden in Rechnung gestellt.
        </p>
        <p>
          <strong>6.4</strong> Soweit die bestellte Ware nicht verfügbar ist,
          behalten wir uns vor, vom Vertrag zurückzutre-ten. In diesem Fall
          werden wir Sie unverzüglich informieren und Ihnen ggf. die Lieferung
          ei-nes vergleichbaren Produktes vorschlagen. Wenn kein vergleichbares
          Produkt verfügbar ist oder Sie keine Lieferung eines vergleichbaren
          Produktes wünschen, werden wir Ihnen eine ggf. bereits erbrachte
          Gegenleistung unverzüglich zurückerstatten.
        </p>
      </div>
      <div id='payment'>
        <h4>7. Zahlung</h4>
        <p>
          <strong>7.1.</strong> Die Zahlung erfolgt über den
          Zahlungsdienstleister "Stripe" wahlweise per Kreditkarte, Paypal oder
          Rechnung.
          <p>
            Nach Abgabe der Bestellung im Shop fordern wir Stripe zur Einleitung
            der Zahlungstransaktion über eine der genannten Zahlungsmethoden
            auf. Weitere Hinweise erhalten Sie beim Bestellvorgang.
          </p>
        </p>
        <p>
          Bei Zahlung per Kreditkarte wird der Kaufpreis bei Abschluss des
          Bestellvorgangs auf Ihrer Kreditkarte eingezogen.
        </p>
        <p>
          Bei der Bezahlung mit PayPal werden Sie im Bestellprozess auf die
          Webseite des Online-Anbieters PayPal weitergeleitet. Um den
          Rechnungsbetrag über PayPal bezahlen zu können, müssen Sie dort
          registriert sein bzw. sich erst registrieren, mit Ihren Zugangsdaten
          legitimieren und die Zahlungsanweisung an uns bestätigen.
        </p>
        <p>
          Bei Zahlung per Rechnung erhalten Sie eine Rechnung per Mail. Unsere
          Rechnungen sind innerhalb 30 Tagen zu zahlen.
        </p>
        <p>
          <strong>7.2.</strong> Bei der Zahlungsart Vorkasse nennen wir Ihnen
          unsere Bankverbindung in der Auftragsbestätigung und liefern die Ware
          nach Zahlungseingang.
        </p>
      </div>
      <div id='reservation'>
        <h4>8. Eigentumsvorbehalt</h4>
        <p>Bis zur vollständigen Zahlung bleibt die Ware unser Eigentum.</p>
      </div>
      <div id='warranty'>
        <h4>9. Sachmängelgewährleistung</h4>
        <p>
          <strong>9.1</strong>
          miapapeteria haftet für Sachmängel nach den hierfür geltenden
          gesetzlichen Vorschriften, insbesondere §§ 434 ff Bürgerliches
          Gesetzbuch.
        </p>
        <p>
          <strong>9.2</strong>Reklamationen auf Grund falsch gelieferter oder
          fehlerhafter Ware werden nur innerhalb von 8 Tagen nach Erhalt der
          Ware anerkannt. Senden Sie uns die eventuell fehlerhafte Wa-re nach
          telefonischer Rücksprache bitte zum Umtausch zurück. Rechnungsabzüge
          erken-nen wir nicht an. Portokosten für Rücksendungen, die mit uns
          vorher abgesprochen wur-den, werden von uns erstattet.
        </p>
      </div>
      <div id='dispute'>
        <h4>10. Streitbeilegung</h4>
        <p>
          Die EU-Kommission hat eine Internetplattform zur Online-Beilegung von
          Streitigkeiten geschaffen. Die Plattform dient als Anlaufstelle zur
          außergerichtlichen Beilegung von Streitigkeiten betreffend
          vertragliche Verpflichtungen, die aus Online-Kaufverträgen erwachsen.
          Nähere Informationen sind unter dem folgenden Link verfügbar:{' '}
          <a href='http://ec.europa.eu/consumers/odr'>
            http://ec.europa.eu/consumers/odr
          </a>
          . Zur Teilnahme an einem Streitbeilegungsverfahren vor einer
          Verbraucherschlichtungsstelle sind wir weder bereit noch verpflichtet.
          <br></br>Unsere Mail Adresse lautet info@miapapeteria.de
        </p>
      </div>

      {/* More tabs can be added here with similar structure */}
    </>
  );

  return <BaseLayout children={content}></BaseLayout>;
}

export default AGBPage;
